import * as React from "react"

import {IconButton} from "./icon-button"
import {
  ModalContainer,
  ModalFooter,
  ModalBody,
  ModalHeader,
} from "./modal.style"

/**
 * @typedef {object} ModalProps
 * @property {boolean=} show
 * @property {() => void} onCloseClick
 * @property {boolean=} closeDisabled
 *
 * @typedef {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} RawButtonProps
 * @typedef {RawButtonProps & ButtonOwnProps} ButtonProps
 */

/**
 * @type {React.FunctionComponent<ModalProps>}
 */
export const Modal = ({
  children,
  show = false,
  onCloseClick,
  closeDisabled = false,
  ...props
}) => {
  return (
    <ModalContainer className="full-screen-mask" hidden={!show}>
      <div className="modal-content">
        <IconButton
          className="modal-close-btn"
          icon="btn-close"
          size={30}
          disabled={closeDisabled}
          onClick={onCloseClick}
        />
        {children}
      </div>
    </ModalContainer>
  )
}

export {ModalFooter, ModalBody, ModalHeader}
